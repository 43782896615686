@import "~bootstrap/scss/bootstrap";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";

@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "header";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";

// .style={{ height: "60px", padding: "0px" }}
.fenillogo {
    height: 60px;
    padding: 0px;
    margin-top: 13px;

    @media screen and (max-width: 768px) {
        height: 50px;
        margin-top: 0px;
    }
}

.iconmargin {
    @media screen and (max-width: 768px) {
        margin-top: 10px;
    }
}

.wa-display {
    // style={{ display: "flex", justifyContent: "space-around",flexWrap:"wrap" }}
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }

    // also in tablet view
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
    }
}

// style={{
//     display: "grid",
//     gridTemplateColumns: "repeat(4, 1fr)",
//     gap: "20px",
//   }}

.home-product-gird-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.my-fenil-logo {
    // style={{
    //   width: "230px",
    //   padding: "10px",
    // }}
    width: 230px;
    padding: 10px;
    @media screen and (max-width: 768px) {
        width: 170px;
        padding: 0px;
    }
}